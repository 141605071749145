// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-01-sdk-sdk-mdx": () => import("./../../../../src/01_sdk/sdk.mdx" /* webpackChunkName: "component---src-01-sdk-sdk-mdx" */),
  "component---src-01-short-sdk-mdx": () => import("./../../../../src/01_short/sdk.mdx" /* webpackChunkName: "component---src-01-short-sdk-mdx" */),
  "component---src-01-vod-sdk-mdx": () => import("./../../../../src/01_vod/sdk.mdx" /* webpackChunkName: "component---src-01-vod-sdk-mdx" */),
  "component---src-02-checkout-vtex-checkout-mdx": () => import("./../../../../src/02_checkout_vtex/checkout.mdx" /* webpackChunkName: "component---src-02-checkout-vtex-checkout-mdx" */),
  "component---src-03-checkout-shopify-checkout-mdx": () => import("./../../../../src/03_checkout_shopify/checkout.mdx" /* webpackChunkName: "component---src-03-checkout-shopify-checkout-mdx" */),
  "component---src-05-checkout-vnda-checkout-mdx": () => import("./../../../../src/05_checkout_vnda/checkout.mdx" /* webpackChunkName: "component---src-05-checkout-vnda-checkout-mdx" */),
  "component---src-06-checkout-woocommerce-checkout-mdx": () => import("./../../../../src/06_checkout_woocommerce/checkout.mdx" /* webpackChunkName: "component---src-06-checkout-woocommerce-checkout-mdx" */),
  "component---src-07-checkout-braavo-checkout-mdx": () => import("./../../../../src/07_checkout_braavo/checkout.mdx" /* webpackChunkName: "component---src-07-checkout-braavo-checkout-mdx" */),
  "component---src-08-checkout-linx-checkout-mdx": () => import("./../../../../src/08_checkout_linx/checkout.mdx" /* webpackChunkName: "component---src-08-checkout-linx-checkout-mdx" */),
  "component---src-08-checkout-vipcommerce-checkout-mdx": () => import("./../../../../src/08_checkout_vipcommerce/checkout.mdx" /* webpackChunkName: "component---src-08-checkout-vipcommerce-checkout-mdx" */),
  "component---src-09-checkout-jetcommerce-checkout-mdx": () => import("./../../../../src/09_checkout_jetcommerce/checkout.mdx" /* webpackChunkName: "component---src-09-checkout-jetcommerce-checkout-mdx" */),
  "component---src-10-checkout-magento-checkout-mdx": () => import("./../../../../src/10_checkout_magento/checkout.mdx" /* webpackChunkName: "component---src-10-checkout-magento-checkout-mdx" */),
  "component---src-11-checkout-webstorm-checkout-mdx": () => import("./../../../../src/11_checkout_webstorm/checkout.mdx" /* webpackChunkName: "component---src-11-checkout-webstorm-checkout-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

